<!--
 * @Description:客户安装工艺列表
 * @Author: ChenXueLin
 * @Date: 2021-10-13 19:26:59
 * @LastEditTime: 2022-10-24 15:12:38
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <!-- 搜索头部 start -->
      <table-title @resize="handleTableTitleResize">
        <template v-slot:searchForm>
          <el-form class="search-list" ref="searchForm" :model="searchForm">
            <el-form-item class="search-item--1" prop="corpName">
              <el-input
                v-model="searchForm.corpName"
                placeholder="客户名称"
                title="客户名称"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="standardDay">
              <el-input
                v-model="searchForm.standardDay"
                placeholder="标准天数"
                title="标准天数"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="address">
              <el-input
                v-model="searchForm.address"
                placeholder="地区"
                title="地区"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--buttons">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button class="reset" @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>
        <template v-slot:operateAction>
          <i class="e6-icon-add_line" title="新增" @click="handleAdd(true)"></i>
          <i
            class="e6-icon-check_line1"
            title="启用"
            @click="changeStatus(1)"
          ></i>
          <i
            class="e6-icon-prohibit_line"
            title="禁用"
            @click="changeStatus(0)"
          ></i>
          <el-button type="primary" @click="importXlxs">导入</el-button>
        </template>
      </table-title>
      <!-- 搜索头部 end -->

      <!-- 表格 start -->
      <section class="table-wrapper">
        <el-table
          border
          :height="resizeViewHeight + 'px'"
          :data="tableData"
          highlight-current-row
          class="elTable"
          ref="elTable"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            type="selection"
            width="50"
            align="center"
          ></el-table-column>
          <!-- 序号 start -->
          <el-table-column
            label="序号"
            width="50"
            fixed="left"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <span>
                {{
                  scope.$index +
                    1 +
                    (searchForm.pageIndex - 1) * searchForm.pageSize
                }}
              </span>
            </template>
          </el-table-column>
          <!-- 序号 end -->
          <el-table-column
            :render-header="renderHeader"
            show-overflow-tooltip
            v-for="(column, index) in columnData"
            :key="index"
            :prop="column.fieldName"
            :label="column.fieldLabel"
            :min-width="column.width"
            :fixed="column.fixed"
            :align="column.align"
            header-align="center"
          >
            <template slot-scope="{ row }">
              <div
                class="table-label"
                v-if="column.fieldName === 'tagValue' && row[column.fieldName]"
              >
                <span
                  class="label-box"
                  v-for="(item, index) in row.tagValue"
                  :key="index"
                >
                  {{ item }}
                </span>
              </div>
              <span
                v-else-if="column.fieldName === 'taskStatusName'"
                :class="[
                  'status',
                  row.taskStatus == 3 || row.taskStatus == 4
                    ? 'finish'
                    : row.taskStatus === 2
                    ? 'ongoing'
                    : ''
                ]"
              >
                {{ row[column.fieldName] }}
              </span>
              <span v-else>{{ row[column.fieldName] }}</span>
            </template>
          </el-table-column>
          <el-table-column fixed="right" align="center" label="操作" width="60">
            <template slot-scope="scope">
              <el-button type="text" @click="handleEdit(scope.row)">
                编辑
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </section>
      <!-- 表格 end -->

      <!-- 分页 start -->
      <section class="pagination-wrapper fixed-section" ref="paginationWrapper">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
      <!-- 分页 end -->
    </div>
    <!-- 新增 -->
    <el-dialog
      v-dialogDrag
      :title="isAdd ? '新增' : '编辑'"
      :visible.sync="addVisible"
      width="450px"
      :before-close="handleClose"
      :close-on-click-modal="false"
      v-loading="dialogLoading"
      :element-loading-background="loadingBackground"
      custom-class="custom-dialog"
      v-if="addVisible"
    >
      <el-form
        ref="addForm"
        label-width="120px"
        :model="addForm"
        :rules="addFormRules"
      >
        <el-form-item label="客户名称" prop="workCorpId" v-if="isAdd">
          <e6-vr-select
            v-model="addForm.workCorpId"
            :data="corpList"
            placeholder="客户名称"
            title="客户名称"
            clearable
            virtual
            remote
            :is-title="true"
            @filterChange="handleLoadCorpFilter"
            :props="{
              id: 'workCorpId',
              label: 'corpName'
            }"
          ></e6-vr-select>
        </el-form-item>
        <el-form-item label="省市区" prop="addressList">
          <e6-vr-select
            style="width:300px"
            v-model="addForm.addressList"
            :data="addressList"
            placeholder="省市区"
            title="省市区"
            virtual
            clearable
            :is-title="true"
            remote
            multiple
            @filterChange="handleLoadAddressFilter"
            @change="handleCitySelect"
            :loading="selectLoading"
            :props="{
              id: 'fullName',
              label: 'fullName'
            }"
          ></e6-vr-select>
        </el-form-item>
        <el-form-item
          label="标准天数"
          prop="standardDay"
          :rules="
            positiveIntegerValid.required({
              requiredMsg: '请输入天数',
              message: '只能为正整数'
            })
          "
        >
          <el-input
            v-model.trim="addForm.standardDay"
            placeholder="标准天数"
            @input="
              addForm.standardDay = addForm.standardDay.replace(/[^0-9-]+/, '')
            "
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="dialog-footer" slot="footer">
        <el-button class="cancel" @click="handleClose">取消</el-button>
        <el-button type="primary" @click="toAdd">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import { printError } from "@/utils/util";
import { positiveIntegerValid } from "@/utils/validate";
import { e3Api } from "@/config/api";
const { E3_BL_EWOS = "" } = e3Api;
import {
  getStandardDayList,
  createStandardDay,
  updateStandardDay,
  getAddressDownList,
  getWorkCorpList,
  updateStatusStandardDay
} from "@/api";
export default {
  name: "timeManage",
  data() {
    return {
      positiveIntegerValid,
      selectLoading: false,
      searchForm: {
        corpName: "",
        standardDay: "",
        address: "",
        pageIndex: 1,
        pageSize: 20
      },
      selColumnId: [],
      total: 0,
      columnData: [
        {
          fieldName: "corpId",
          display: true,
          fieldLabel: "工单客户ID",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "corpName",
          display: true,
          fieldLabel: "客户名称",
          width: 180,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "corpSource",
          display: true,
          fieldLabel: "上线平台",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "corpSource",
          display: true,
          fieldLabel: "客户来源",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "addressListStr",
          display: true,
          fieldLabel: "地区",
          width: 250,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "standardDay",
          display: true,
          fieldLabel: "标准天数",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "statusName",
          display: true,
          fieldLabel: "状态",
          width: 90,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "updatedByName",
          display: true,
          fieldLabel: "最后一次修改人",
          width: 180,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "updatedTimeStr",
          display: true,
          fieldLabel: "最后一次修改时间",
          width: 180,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //表头
      tableData: [], // 表格数据
      addForm: {
        standardDayId: "0",
        workCorpId: "",
        corpName: "",
        corpId: "",
        corpSource: "",
        standardDay: "",
        addressList: []
      },
      addFormRules: {
        workCorpId: [
          {
            required: true,
            message: "请选择客户名称",
            trigger: ["blur", "change"]
          }
        ],
        standardDay: [
          {
            required: true,
            message: "请输入标准天数",
            trigger: ["blur", "change"]
          }
        ],
        addressList: [
          {
            required: true,
            message: "请选择地址",
            trigger: ["blur", "change"]
          }
        ]
      },
      addressList: [],
      addVisible: false,
      isAdd: true, //默认为新增
      dialogLoading: false,
      allAddressList: [], //查出来的地址数据加选中的地址数据
      selectAddressList: [] //选中的地址
    };
  },
  components: {},
  mixins: [listPage, listPageReszie, base],
  watch: {},
  created() {
    this.queryList();
  },
  mounted() {
    this.setTableScrollDOM("elTable");
  },
  activated() {
    this.setScrollRecord();
  },

  methods: {
    //客户下拉框处理逻辑
    handleLoadCorpFilter: _.debounce(async function(val) {
      if (val) {
        this.searchForm.corpId = "";
        this.loadCorpData(val);
      }
    }, 300),
    //加载客户名称
    async loadCorpData(val) {
      try {
        let res = await getWorkCorpList({
          pageNum: 1,
          pageSize: 200,
          corpName: val
        });
        //获取客户名称
        this.corpList = this.getFreezeResponse(res, {
          path: "data.array"
        });
      } catch (error) {
        printError(error);
      }
    },
    //点击编辑
    handleEdit(row) {
      let rowAddressList = row.addressList;
      rowAddressList.map(item => {
        item.provinceCode = item.provinceId;
        item.countyCode = item.countyId;
        item.cityCode = item.cityId;
      });
      this.selectAddressList = rowAddressList;

      let addressList = rowAddressList.length
        ? [
            {
              fullName: "全国",
              provinceId: 10000000,
              provinceName: "全国"
            },
            ...rowAddressList
          ]
        : [];
      let map = new Map();
      for (let item of addressList) {
        if (!map.has(item.fullName)) {
          map.set(item.fullName, item);
        }
      }
      this.addressList = [...map.values()];
      this.allAddressList = [...this.addressList];
      this.addForm.addressList = row.addressList.length
        ? row.addressList.map(item => item.fullName)
        : [];
      this.addForm.standardDay = row.standardDay;
      this.addForm.standardDayId = row.standardDayId;
      this.isAdd = false;
      this.addVisible = true;
      this.loadCorpData();
    },
    //查询全部任务单
    async queryList() {
      try {
        this.loading = true;
        let res = await getStandardDayList(this.searchForm);
        let tableData = res.data.array;
        tableData.map(item => {
          item.addressListStr = item.addressList
            .map(ele => ele.fullName)
            .join(",");
        });
        this.tableData = tableData;
        this.total = this.getFreezeResponse(res, {
          path: "data.totalRecords"
        });
        this.$nextTick(() => {
          this.$refs.elTable.doLayout();
        });
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    // 表格勾选时间
    handleSelectionChange(columns) {
      this.selColumnId = columns.map(item => item.standardDayId);
    },
    //添加
    handleAdd() {
      this.isAdd = true;
      this.addVisible = true;
      this.loadAddressData();
      this.loadCorpData();
    },
    handleLoadAddressFilter: _.debounce(async function(val) {
      if (val) {
        // this.addForm.addressList = [];
        this.loadAddressData(val);
      }
    }, 300),
    //加载地址列表
    async loadAddressData(val = "") {
      try {
        this.selectLoading = true;
        let res = await getAddressDownList({
          addressName: val,
          level: 3,
          pageIndex: 1,
          pageSize: 300
        });
        //获取地址下拉框
        this.addressList = [
          {
            fullName: "全国",
            provinceId: 10000000,
            provinceName: "全国"
          },
          ...res.data.array
        ];
        this.allAddressList = [...this.addressList, ...this.selectAddressList];
      } catch (error) {
        printError(error);
      } finally {
        this.selectLoading = false;
      }
    },
    // 处理城市选择
    handleCitySelect() {
      this.$nextTick(() => {
        let list = this.allAddressList.filter(ele => {
          return (
            this.addForm.addressList.filter(x => x === ele.fullName).length > 0
          );
        });
        this.selectAddressList = _.cloneDeep(list);
      });
    },
    // 导入
    importXlxs() {
      let route = {
        name: "uploadCenter",
        params: {
          refresh: true,
          moduleKey: "corp_standard_day_import",
          origin: "baseinfoManage/timeManage",
          serverName: E3_BL_EWOS
        }
      };
      this.routerPush(route);
    },
    toAdd() {
      this.$refs.addForm.validate(valid => {
        if (valid) {
          if (this.isAdd) {
            this.createReq();
          } else {
            this.updateReq();
          }
        }
      });
    },
    //选中客户
    handleWorkCorp(val, node) {
      this.addForm = { ...this.addForm, ...node };
    },
    handleParmas() {
      let selectAddressList = this.selectAddressList;
      console.log(selectAddressList, "selectAddressList");
      let addressList = selectAddressList.map(item => {
        return Object.assign(
          {},
          {
            cityId: item.cityCode,
            cityName: item.cityName,
            countyId: item.countyCode,
            countyName: item.countyName,
            provinceId: item.provinceCode,
            provinceName: item.provinceName,
            fullName: item.fullName
          }
        );
      });
      return addressList;
    },
    //新增请求
    async createReq() {
      try {
        this.dialogLoading = true;
        let res = await createStandardDay({
          ...this.addForm,
          addressList: this.handleParmas()
        });
        if (res.code == "OK") {
          this.$message.success("创建成功");
          this.handleClose();
          this.queryList();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    //修改请求
    async updateReq() {
      try {
        this.selectLoading = true;
        let res = await updateStandardDay({
          ...this.addForm,
          addressList: this.handleParmas()
        });
        if (res.code == "OK") {
          this.$message.success("修改成功");
          this.handleClose();
          this.queryList();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.selectLoading = false;
      }
    },
    //启用、禁用toAdd
    changeStatus(type) {
      if (!this.selColumnId.length) {
        this.$message.warning("请选择数据");
        return;
      }
      let tip, title;
      if (type == 1) {
        tip = "是否确认启用";
        title = "启用";
      } else {
        tip = "是否确认禁用";
        title = "禁用";
      }
      this.$confirm(tip, title, {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.enableErrorReq(type);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    //启用/禁用请求
    async enableErrorReq(status) {
      try {
        this.loading = true;
        let res = await updateStatusStandardDay({
          standardDayIdList: this.selColumnId,
          status
        });
        if (res.code == "OK") {
          if (status == 0) {
            this.$message.success("禁用成功");
          } else {
            this.$message.success("启用成功");
          }
          this.selColumnId = [];
          this.$refs.elTable.clearSelection();
          this.queryList();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //关闭弹框
    handleClose() {
      this.addForm = {
        workCorpId: "",
        corpName: "",
        corpId: "",
        corpSource: "",
        standardDayId: "",
        standardDay: "",
        addressList: []
      };
      this.allAddressList = []; //查出来的地址数据加选中的地址数据
      this.selectAddressList = [];
      this.$refs.addForm.resetFields();
      this.addVisible = false;
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/.custom-dialog {
  .el-dialog__body {
    padding: 20px 18px 15px 18px;
    .el-form-item {
      .el-input {
        width: 220px;
      }
    }
    .tag-content {
      display: flex;
      align-items: center;
      // justify-content: center;
      flex-wrap: wrap;
    }
    .el-tag {
      margin-right: 10px;
      margin-top: 10px;
    }
  }
}
</style>
